<template>
<div>
	<q-item tag="label" class="list-element q-mx-sm q-my-md row">
		<div class="word-break col-8 text-left">
			{{form.name}}
		</div>
		<div class="col-4 flex justify-end">
			<q-btn
				flat
				round
				color="dark"
				:icon="!form.is_inherited ? 'o_edit' : 'o_visibility'"
				@click="editForm()"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Edit Form
				</q-tooltip>
			</q-btn>
			<q-btn
				v-if="!form.is_inherited"
				flat
				round
				color="dark"
				class="handle"
				icon="o_delete"
				@click="(delete_incident = true)"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Delete
				</q-tooltip>
			</q-btn>
			<q-btn
				flat
				round
				color="dark"
				class="handle"
				icon="o_drag_handle"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Drag and drop
				</q-tooltip>
			</q-btn>
		</div>
	</q-item>
	<q-dialog v-model="edit_incident_name">
		<q-card style="min-width: 350px">
			<q-card-section>
				<div class="text-h6">Edit form name</div>
			</q-card-section>

			<q-card-section class="q-pt-none">
				<q-input
					dense
					label="Forn name Name"
					v-model="edited_incident_name"
					autofocus
					:error="has_edit_name_error"
					:error-message="edit_name_error_message"
				/>
			</q-card-section>

			<q-card-actions align="right" class="text-primary">
				<q-btn flat label="Cancel" v-close-popup />
				<q-btn flat label="Save" @click="updateName" />
			</q-card-actions>
		</q-card>
	</q-dialog>
	<q-dialog v-model="delete_incident">
		<q-card style="min-width: 350px">
			<q-card-section>
				<div>Are you sure you want to delete this form?</div>
			</q-card-section>
			<q-card-actions align="right" class="text-primary">
				<q-btn flat label="Cancel" v-close-popup />
				<q-btn flat label="Delete" @click="deleteForm" />
			</q-card-actions>
		</q-card>
	</q-dialog>
</div>
</template>

<script>
import FieldGroup from '@/components/incidents/forms/FieldGroup.vue';
import FieldGroupAdd from '@/components/incidents/forms/FieldGroupAdd.vue';
import draggable from 'vuedraggable';

import FieldGroupsAPI from '@/services/api/IncidentFieldGroup.js';
import lexorder from "@/services/lexorder.js";

import { mapActions, mapGetters } from "vuex";

import { required } from 'vuelidate/lib/validators';
import { uniqueIn } from "@/services/validators.js";

export default {
	name: "Form",
	components: {
		FieldGroup,
		FieldGroupAdd,
		draggable
	},
	props: ["form", "forms", "new_form"],
	data (){
		return{
			drag: false,
			retrieve_form: {},
			show_delete_dialog: false,
			new_field_group: {
				display_conditions: [],
				content: "",
				field_mappings: [],
				form: this.form.id
			},
			force_save: false,
			form_name: this.form.name,
			error_message: "",
			show_field_settings: false,
			is_saved: false,
			edit_incident_name: false,
			delete_incident: false,
			edited_incident_name: '',
			has_edit_name_error: false,
			edit_name_error_message: ''
		}
	},
	computed: {
		...mapGetters(['field_groups']),
		field_groups_list: {
			get: function() {
				return Object.values(this.field_groups).sort(function(fg1, fg2) {
					if(fg1.order < fg2.order) { return -1; }
					if(fg1.order > fg2.order) { return 1; }
					return 0;
				});
			},
			set: function(new_val) {
				this.field_groups_list.splice(0, this.field_groups_list.length)
				for (let fg of new_val) {
					this.field_groups_list.push(fg);
				}
			}
		},
		show_all_fields: {
			get: function() {
				let list_show_fields = Object.values(this.$store.getters.field_groups).map(function (field_group) {
					return field_group.show_fields
				});
				return list_show_fields.some(x => !!x)
			},
			set: function(new_val) {
				this.$store.dispatch("toggleAllFieldsVisibility", new_val)
			}
		},
		show_all_conditions: {
			get: function() {
				let list_show_conditions = Object.values(this.$store.getters.field_groups).map(function (field_group) {
					return field_group.show_conditions
				});
				return list_show_conditions.some(x => !!x)
			},
			set: function(new_val) {
				this.$store.dispatch("toggleAllConditionsVisibility", new_val)
			}
		},
		show_all_content: {
			get: function() {
				let list_show_content = Object.values(this.$store.getters.field_groups).map(function (field_group) {
					return field_group.show_content
				});
				return list_show_content.some(x => !!x)
			},
			set: function(new_val) {
				this.$store.dispatch("toggleAllContentVisibility", new_val)
			}
		},
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},
	},
	validations: {
		form_name: {
			required,
			unique: uniqueIn('forms', 'form'),
		}
	},
	methods: {
		...mapActions(['addFieldGroup', 'retrieveFieldGroups', 'resetFieldGroups', 'getAllFields', 'updateFieldGroup']),
		deleteForm() {
			this.$emit('deleteForm', this.form.id);
			this.delete_incident = false
		},
		async sort(event) {
			let curr = this.field_groups_list[event.newIndex];
			curr.order = lexorder.update(event.newIndex, this.field_groups_list);
			
			FieldGroupsAPI.patchFieldGroup(curr.id, {order: curr.order})
		},
		validate() {
			this.edit_name_error_message = ''
			const val = this.$v.form_name;
			val.$touch();
			this.edit_name_error_message = (!val.required) ? 'Please enter a name for the Form' :
				(!val.unique) ? 'The form name must be unique' :
					"";
			return !this.$v.$anyError;
		},
		documentClick(e) {
			let target = e.target;
			if(!(target.id == 'prompt-dialog' || target.parentElement.id == 'prompt-dialog' || target.id == 'form=' + this.form.id || target.type == 'checkbox')){
				this.show_field_settings = false;
			}
		},
		updateName(){
			this.has_edit_name_error = false
			if (!this.validate()){
				this.has_edit_name_error = true
				return
			}
			this.edit_incident_name = false
			this.$emit('updateName', this.edited_incident_name)
		},
		editForm(){
			this.$router.push({ name: 'Edit Form', params: { id: this.form.id } })
		}
	},
	watch: {
		new_form: async function() {
			for(let i in this.forms){
				if(this.forms[i].id == this.new_form){
					this.forms[i].is_open = true;
				}
			}
		},
		form_name: function() {
			this.validate() && this.$emit('updateName', this.form_name)
		}
	},
	async created() {
		document.addEventListener("click", this.documentClick);
		this.getAllFields();
	},
	destroyed(){
		document.removeEventListener("click", this.documentClick);
	},
}
</script>

<style lang="scss" scoped>
	.list-element {
		padding: 0.5rem;
		margin-bottom: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid $grey-4;
		background: $grey-1;
		font-size: 1rem;
		flex-direction: row;
		text-align: left;
		border-radius: 4px;
		&:hover {
			background-color: $grey-2;
		}
	}
	.word-break{
		word-break: break-word;
	}
</style>
