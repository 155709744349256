<template>
	<q-card style="width: 700px; max-width: 80vw">
		<q-card-section class="text-center">
			<div class="text-h5">Add new form</div>
		</q-card-section>
		<q-card-section>
			<q-input
				label="Form name"
				class="full-width"
				v-model="form_name"
				:error="has_name_error"
				:error-message="name_error_message"
			/>
		</q-card-section>
		<q-card-actions align='right'>
			<q-btn flat label="Cancel" color="primary" @click="$emit('closeForms')" />
			<q-btn flat label="Add" color="primary" @click="addForm(form_name)"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import FormsAPI from "@/services/api/IncidentForm.js";
import lexorder from "@/services/lexorder.js";

import { mapActions } from "vuex";

import { required } from 'vuelidate/lib/validators';
import { uniqueIn } from "@/services/validators.js";
export default {
	name: "IncidentFormAdd",
	data() {
		return {
			new_form: { name: ""},
			field_selection: '',
			has_name_error: false,
			name_error_message: "",
			has_field_error: false,
			field_error_message: '',
		};
	},
	props: { forms: Array },
	computed: {
		form_name: {
			set: function (new_val) {
				return this.new_form.name = new_val;
			},
			get: function () {
				return this.new_form.name;
			}
		}
	},
	validations: {
		form_name: {
			required,
			unique: uniqueIn('forms', 'form_name'),
		}
	},
	methods: {
		...mapActions(['resetFieldGroups']),
		async addForm(name = this.new_form.name) {
			this.name_error_message = ""
			this.has_name_error = false
			this.resetFieldGroups();
			if (!this.validate()){
				this.has_name_error = true
				return
			}
			if (this.form_name.length > 100){
				this.has_name_error = true
				this.name_error_message = 'Please ensure that the form name has no more than 100 characters'
				return
			}

			let order = lexorder.generate(this.forms);
			let response = await FormsAPI.addForm(name, order);
			if (response.error_status==400) {
					this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.$emit('closeForms')
				return
			}
			delete response.data.field_groups;
			delete response.data.customer_group;
			response.data.is_open = false;
			this.forms.push(response.data);
			this.$emit("closeForms", response.data.id);
			this.$router.push({ name: 'Edit Form', params: { id: response.data.id } })
			this.new_form.name = '';
			this.error_message = '';
		},
		validate() {
			let val = this.$v.form_name;
			val.$touch();
			this.name_error_message = (!val.required) ? 'Please enter name for the form' :
				(!val.unique) ? 'The form name must be unique' : "";
			return !this.$v.$anyError;
		}
	},
}
</script>