<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Forms
		</h1>
		<draggable
			v-model="forms"
			v-bind="dragOptions"
			handle=".handle"
			@start="drag = true"
			@end="drag = false"
			@sort="sort"
		>
			<transition-group name="fade">
				<q-list
					v-for="form in forms"
					:key="form.id"
				>
					<Form
						:form="form"
						:forms="forms"
						:new_form="new_form"
						@updateName="updateName($event, form.id)"
						@deleteForm="deleteForm"
						@closeForms="closeForms"
					/>
				</q-list>
			</transition-group>
		</draggable>
		<div class="field-fab-wrapper q-pt-md">
			<q-btn
				v-if="$q.screen.gt.sm"
				size="md"
				padding="md lg"
				color="primary"
				icon="add"
				label="Add Form"
				rounded
				@click="() => (add_form = true)"
			/>
			<q-btn
				v-else
				size="lg"
				color="primary"
				icon="add"
				round
				@click="() => (add_form = true)"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Add Form
				</q-tooltip>
			</q-btn>
		</div>
		<q-dialog v-model="add_form">
			<FormAdd
				:forms="forms"
				@closeForms="closeForms"
			/>
		</q-dialog>
	</div>
</template>

<script>
import Form from '@/components/incidents/forms/Form.vue';
import FormAdd from '@/components/incidents/forms/FormAdd.vue';
import FormAPI from '@/services/api/IncidentForm.js';
import Utils from '@/services/utils.js';
import lexorder from "@/services/lexorder.js";
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

export default {
	name: "Forms",
	components: {
		draggable,
		Form,
		FormAdd,
	},
	data(){
		return{
			forms: [],
			new_form: false,
			drag: false,
			add_form: false
		}
	},
	computed: {
		...mapGetters(['current_CG', 'logged_user']),
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		}
	},
	methods: {
		async updateName(new_name, form_id) {
			let response = await FormAPI.updateForm(form_id, new_name);
			if (response.error){
					this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.forms = this.forms.map(form =>
				(form.id == form_id) ? { ...form, name: new_name } : form);
			this.$q.notify('Form name edited successfully.')
		},
		async deleteForm( id ) {
			const response = await FormAPI.deleteForm(id);
			if(response.error){
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				return
			}
			this.forms = this.forms.filter(form => form.id != id);
			this.$q.notify('Form deleted successfully.')
		},
		async closeForms(new_form_id, open_form){
			for (let i in this.forms){
				let form = Utils.deepCopy(this.forms[i])
				if(this.forms[i].id == open_form){
					form.is_open = true
					this.$set(this.forms, i, form)
				}
				else{
					form.is_open = false
					this.$set(this.forms, i, form)
				}
			}
			if (new_form_id) {
				this.new_form = new_form_id
			}
			this.add_form = false
		},
		async sort(event) {
			let curr = this.forms[event.newIndex];
			curr.order = lexorder.update(event.newIndex, this.forms);
			await FormAPI.patchForm(curr.id, { order: curr.order });
		},
		async getForms(){
			this.forms = await FormAPI.getForms()
			if(this.forms.error){
				this.forms = []
			}else{
				for (let i in this.forms){
					this.forms[i].is_inherited = this.current_CG.customer_group.parent && this.forms[i].customer_group !== this.logged_user.customer_group
					this.forms[i].is_open = false;
					this.forms[i].display_settings = false;
				}
			}
		}
	},
	async created() {
		this.$q.loading.show({
			delay: 400
		})
		await this.getForms()
		this.$q.loading.hide()
	}
}
</script>
